.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    z-index: 1000;
    display: none; /* Hide by default */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.cookie-consent-banner span {
    flex: 1 1 100%;
    margin-bottom: 10px;
}
.button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}
.cookie-consent-banner button {
    background-color: $success;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 5px;
}

.cookie-consent-banner button.exit {
    background-color: $danger;
}

.cookie-consent-message {
   margin-bottom:5px !important;
}

@media (min-width: 600px) {
    .cookie-consent-banner {
        flex-wrap: nowrap;
    }
    .cookie-consent-banner span {
        flex: 1;
        margin-bottom: 0;
    }
}